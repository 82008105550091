export class GoogleAddressModel {
    address_line_1!: string;
    address_line_2?: string;
    suburb?: string;
    town_city?: string;
    state_region?: string;
    post_code?: string;
    country?: string;
    isStreetAddress?:boolean
}
