import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AccountModel,
    ProductModel,
    QuoteOrderSampleModel,
    RequestParamModel,
    ResponseModel,
    TradeTypeModel,
    UserModel
} from '../models';
import { PaginationModel } from '../models';
import { BaseService } from '../services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AccountsService {

    constructor(private baseService: BaseService) {
    }

    // get all user list
    getAccount(requestParamModel: RequestParamModel): any {
        return this.baseService.get('account', requestParamModel)
            .pipe(map<any, any>(response => {
                const accountList = new PaginationModel<AccountModel>();
                const headers = response.headers;
                accountList.setHeaders(headers);
                accountList.data = response.body.data as AccountModel[];
                return accountList;
            }, (err: any) => {
                return err;
            }));
    }

    // get single user details.
    getAccountAllData(accountId: number, requestParamModel: RequestParamModel): Observable<AccountModel> {
        return this.baseService.get('account/' + accountId, requestParamModel)
            .pipe(map<any, AccountModel>(response => {
                return response.body.data as AccountModel;
            }, (err: any) => {
                return err;
            }));
    }

    // save user if new then add if old then update
    saveAccount(accountModel: any): Observable<number> {
        if (accountModel.id !== undefined) {
            return this.baseService.patch('account/' + accountModel.id, accountModel)
                .pipe(map<any, number>(response => {
                    accountModel = response.data as AccountModel;
                    return accountModel.id;
                }, (err: any) => {
                    return err;
                })
                );
        } else {
            return this.baseService.post('account', accountModel)
                .pipe(map<any, number>(response => {
                    accountModel = response.data as AccountModel;
                    return accountModel.id;
                }, (err: any) => {
                    return err;
                })
                );
        }
    }

    /**
     * Save account with logo
     * @param body
     */
    saveAccountWithLogo(body: any) {
        return this.baseService.uploadPost(`account`, body).pipe(
            map<any, any>(response => {
                return response.data;
            }, (error: any) => {
                return error;
            })
        )
    }

    getAccountAddress(requestParamModel: RequestParamModel): any {
        return this.baseService.get('account-address', requestParamModel)
            .pipe(map<any, any>(response => {
                const accountList = new PaginationModel<AccountModel>();
                const headers = response.headers;
                accountList.setHeaders(headers);
                accountList.data = response.body.data as AccountModel[];
                return accountList;
            }, (err: any) => {
                return err;
            }));
    }

    // save user if new then add if old then update
    saveAccountAddress(accountModel: any): Observable<number> {
        return this.baseService.post('account-address', accountModel)
            .pipe(map<any, number>(response => {
                accountModel = response.data as AccountModel;
                return accountModel;
            }, (err: any) => {
                return err;
            })
            );
    }

    updateAccountAddress(model: any): Observable<number> {
        return this.baseService.patch('account-address/' + model.id, model)
            .pipe(map<any, number>(response => {
                model = response.data as any;
                return model.id;
            }, (err: any) => {
                return err;
            })
            );
    }

    // save user if new then add if old then update
    saveContactAddress(accountModel: any): Observable<any> {
        return this.baseService.post('frontend-user-address', accountModel)
            .pipe(map<any, number>(response => {
                accountModel = response.data as AccountModel;
                return accountModel;
            }, (err: any) => {
                return err;
            })
            );
    }

    // save user if new then add if old then update
    viewAddress(id: any, accountModel: any): Observable<any> {
        return this.baseService.get('frontend-user-address/' + id, accountModel)
            .pipe(map<any, number>(response => {
                return response?.body?.data;
            }, (err: any) => {
                return err;
            })
            );
    }

    deleteAccount(_id: any) {
        return this.baseService.delete('account/' + _id)
            .pipe(map<any, number>(response => {
                return response;
            }, (err: any) => {
                return err;
            })
            );
    }

    deleteAccountAddress(_id: any) {
        return this.baseService.delete('account-address/' + _id)
            .pipe(map<any, number>(response => {
                return response;
            }, (err: any) => {
                return err;
            })
            );
    }

    uploadLogo(_id: any, body: any) {
        return this.baseService.uploadPost('account/update-logo/' + _id, body)
            .pipe(map<any, any>(response => {
                return response.data;
            }, (err: any) => {
                return err;
            }));
    }

    // get all user list
    getAccountByABN(requestModel: AccountModel): any {
        return this.baseService.postWithoutToken('account/get-by-abn?expand=companyAddresses,companyAddresses.fullAddress,defaultAddress', requestModel)
            .pipe(map<any, AccountModel>(response => {
                return response.data;
            }, (err: any) => {
                return err;
            }));
    }

    // Get Trade Lists
    getTradeTypeLists(requestParamModel: RequestParamModel): any {
        return this.baseService.getWithoutToken('trade-type', requestParamModel)
            .pipe(map<any, any>(response => {
                const responseList = new PaginationModel<TradeTypeModel>();
                const headers = response.headers;
                responseList.setHeaders(headers);
                responseList.data = response.body.data as TradeTypeModel[];
                return responseList;
            }, (err: any) => {
                return err;
            }));
    }

    getAccountByCompanyName(requestModel: any) {
        return this.baseService.postWithoutToken('account/get-by-company-name?expand=companyAddresses', requestModel)
            .pipe(map<any, AccountModel>(response => {
                return response.data;
            }, (err: any) => {
                return err;
            }));
    }

    getNote(requestModel: any) {
        return this.baseService.get('note', requestModel)
            .pipe(map<any, any>(response => {
                return response.body.data as any;
            }, (err: any) => {
                return err;
            })
            );
    }

    addUpdateNote(requestModel: any) {
        if (requestModel.noteId) {
            return this.baseService.patch('note/' + requestModel.noteId, requestModel)
                .pipe(map<any, number>(response => {
                    requestModel = response.data as QuoteOrderSampleModel;
                    return requestModel;
                }, (err: any) => {
                    return err;
                })
                );
        } else {
            return this.baseService.post('note', requestModel)
                .pipe(map<any, number>(response => {
                    requestModel = response.data as QuoteOrderSampleModel;
                    return requestModel;
                }, (err: any) => {
                    return err;
                })
                );
        }
    }

    addUpdateNoteWithAttachment(requestModel: any,noteId?: any) {
        if (noteId) {
            return this.baseService.uploadPost(`note/upload/` + noteId, requestModel).pipe(
                map<any, any>(response => {
                    return response.data;
                }, (error: any) => {
                    return error;
                })
            )
        } else {
            return this.baseService.uploadPost(`note`, requestModel).pipe(
                map<any, any>(response => {
                    return response.data;
                }, (error: any) => {
                    return error;
                })
            )
        }
    }



    deleteNote(_id: any) {
        return this.baseService.delete('note/' + _id)
            .pipe(map<any, number>(response => {
                return response;
            }, (err: any) => {
                return err;
            })
            );
    }

    downloadFileContent(bodyData: any) {
        return this.baseService.getDownloadFileContent(`note/download?id=${bodyData.id}`);
    }

    previewUploadFile(id: any) {
        return this.baseService.get('note/preview/' + id)
            .pipe(map<any, any>(response => {
                return response.body.data as any;
            }, (err: any) => {
                return err;
            }));
    }

    deleteSelectedPdfFile(_id: any) {
        return this.baseService.delete('note/delete-attachment/' + _id)
            .pipe(map<any, number>(response => {
                return response;
            }, (err: any) => {
                return err;
            })
            );
    }

    getComapnyName(requestParamModel: RequestParamModel): any {
        return this.baseService.get('product', requestParamModel)
            .pipe(map<any, any>(response => {
                const accountList = new PaginationModel<ProductModel>();
                const headers = response.headers;
                accountList.setHeaders(headers);
                accountList.data = response.body.data as ProductModel[];
                return accountList;
            }, (err: any) => {
                return err;
            }));
    }


}
