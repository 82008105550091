import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RequestParamModel} from "@coremodels";
import {CommonSearchService} from "@coreapiservices";
import {Router} from "@angular/router";

@Component({
  selector: 'app-console-search-dialog',
  templateUrl: './console-search-dialog.component.html',
  styleUrls: ['./console-search-dialog.component.css', '../../../../app.component.css']
})

export class ConsoleSearchDialogComponent implements OnInit {
  searchResult: any = [];
  iskeywordSearchLoading = false;
  keyword = 'common_search';
  searchKeyword: any;
  errorMsg!: string;
  currentPage = 1;
  pageSize = 10; // Adjust the page size as needed
  requestParamModel: RequestParamModel; // Adjust the page size as needed

  constructor(
    public activeModal: NgbActiveModal,
    private changeDetection: ChangeDetectorRef,
    private _service: CommonSearchService,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  search($event: any, isScroll = false) {
    if (!$event) {
      this.searchResult = [];
    } else {
      this.searchKeyword = $event;
      this.iskeywordSearchLoading = true;
      this.changeDetection.detectChanges();
      this.requestParamModel = new RequestParamModel();
      this.requestParamModel.addDefaultFilter('search_keyword', encodeURIComponent($event), 'string');
      this.requestParamModel.pageIndex = this.currentPage;
      this.requestParamModel.pageSize = this.pageSize;
      this._service.getSearchResult(this.requestParamModel).subscribe((dataList: any) => {
        this.requestParamModel.update<any>(dataList);
        //this.searchResult = [...this.searchResult, ...dataList.data];
        if (isScroll) {
          this.searchResult = [...this.searchResult, ...dataList.data];
        } else {
          this.searchResult = [];
          this.searchResult = dataList.data;
        }

        // Filter out duplicate objects based on the id property
        this.searchResult = this.searchResult.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.id === item.id
          ))
        );

        this.searchResult.map(
          (contactList: any) => {
            contactList.common_search = `${contactList.id} ${contactList.title} ${contactList.sub_title} ${contactList.module} ${contactList.bpay_biller_code} ${contactList.bpay_reference} ${contactList.mobile} ${contactList.email}`
          });

        this.changeDetection.detectChanges();
        this.iskeywordSearchLoading = false;
      }, (error: HttpErrorResponse) => {
        this.iskeywordSearchLoading = false;
      });
    }
  }

  selectEvent(item: any) {
    if (item) {
      this.searchResult = [];
      this.activeModal.close();
      if (item.module == 'Account') {
        this.router.navigate(['accounts/' + item.id]);
      } else if (item.module == 'Contact') {
        this.router.navigate(['contacts/' + item.id]);
      } else if (item.module == 'Sample Request') {
        this.router.navigate(['sample-requests/view/' + item.id]);
      } else if (item.module == 'Quote') {
        this.router.navigate(['quotes/view/' + item.id]);
      } else if (item.module == 'Order') {
        this.router.navigate(['orders/view/' + item.id]);
      }
    }
  }

  searchCleared() {
    this.searchResult = [];
  }

  onScroll(): void {
    if (this.currentPage < this.requestParamModel.totalPage) {
      this.currentPage++;
      this.search(this.searchKeyword, true);
    }
  }

  close() {
    this.activeModal.close();
  }
}
