import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

//#region Modal Dialog Service
export class ModalDialogService {

  //#region Constructor
  constructor() { }
  //#endregion

  //#region functions
  getDeleteConfirmation(title:any,body_message:any,cancel_button_text="Cancel",ok_button_text="Delete"){
    let observable = Observable.create((observer:any) => {
      setTimeout(() => {
        var ref:any;
    
        ref.componentInstance.title = title;
        ref.componentInstance.body_message = body_message;
        ref.componentInstance.cancel_button_text = cancel_button_text;
        ref.componentInstance.ok_button_text = ok_button_text;
    
        ref.result.then((result:any) => {
          observer.next(result); // This method same as resolve() method from Angular 1
          observer.complete();//to show we are done with our processing
        });
        
      }, 10);
    
    })

    return observable;
  }
  //#endregion

  //#endregion
}
