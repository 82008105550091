export class EmailTemplateModel {
    id!: number;
    key?: string;
    subject?: string;
    title?: string;
    body?: string;
    hint?: string;
    send_to_admin?: string;
    send_to_user?: string;
    instruction?: string;
    created_at!: any;
    modified_at!: any;
}
