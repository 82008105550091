import { Injectable } from '@angular/core';
import { RequestParamModel } from '../models';
import { PaginationModel } from '../models';
import { BaseService } from '../services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {

  constructor(private baseService: BaseService) { }

  // Get product collection Lists
  getCollection(requestParamModel: RequestParamModel): any {
    return this.baseService.get('product-collection', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<any>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as any[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }
}
