import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PaginationModel, ProjectCategoryModel, RequestParamModel } from '../models';
import { BaseService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class ProjectCategoryService {

  constructor(
    public baseService: BaseService
  ) { }

  public API_CONSTANT = 'project-category'

  getProjectsCategory(requestParamsModel?: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT, requestParamsModel ? requestParamsModel : new RequestParamModel()).pipe(
      map<any, any>(response => {
        const accountList = new PaginationModel<ProjectCategoryModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ProjectCategoryModel[];
        return accountList;
      }, (error: any) => {
        return error;
      })
    )
  }

  saveProjectsCategory(body: any) {
    if (body.id) {
      return this.baseService.patch(`${this.API_CONSTANT}/${body.id}`, body).pipe(
        map<any, any>(response => {
          return response;
        }, ((error: any) => {
          return error;
        }))
      )
    } else {
      return this.baseService.post(this.API_CONSTANT, body).pipe(
        map<any, any>(response => {
          return response;
        }, (error: any) => {
          return error;
        })
      )
    }
  }

  getProjectCategoryById(id: number) {
    return this.baseService.get(`${this.API_CONSTANT}/${id}`).pipe(
      map<any, any>(response => {
        return response.body.data as ProjectCategoryModel
      }, (error: any) => {
        return error;
      })
    )
  }

  deleteProjectCategory(id: number) {
    return this.baseService.delete(`${this.API_CONSTANT}/${id}`).pipe(
      map<any, any>(response => {
        return response;
      }, (error: any) => {
        return error;
      })
    )
  }
}
