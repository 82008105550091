import {Injectable} from '@angular/core';

var Highcharts = require("highcharts/highmaps.js");
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
import proj4 from 'proj4';

interface ExtendedChart extends Highcharts.PlotPackedbubbleOptions {
  layoutAlgorithm: {
    splitSeries: any;
  };
}

@Injectable({
  providedIn: 'root'
})
export class HighchartService {
  constructor() {
  }

  /**
   * Configure column chart here based on common configuration
   *
   * @param tooltipFormatter
   * @param xAxisCategories
   * @param seriesData
   * @param chartTitle
   * @param dataFormat
   */
  configureColumnChart(tooltipFormatter: () => string, xAxisCategories: string[], seriesData: any[], chartTitle: string, dataFormat?: string): any {
    return {
      chart: {
        type: "column",
        backgroundColor: "#2D2D2D",
        borderRadius: 2,
        width: null,
        marginRight: 30,
        title: null, // Remove the chart title
      },
      title: {
        text: chartTitle,
      },
      tooltip: {
        followPointer: false,
        backgroundColor: '#373737',
        color: "#ffffff",
        borderColor: '#373737',
        borderRadius: 5,
        borderWidth: 0,
        shared: true,
        formatter: tooltipFormatter,
        useHTML: true
      },
      xAxis: {
        title: null,
        min: null,
        startOnTick: true,
        scrollbar: {
          enabled: true
        },
        labels: {
          style: {
            color: "#ffffff",
            opacity: 0.7,
            fontSize: '14px',
          },
          formatter: function () {
            if (typeof this.value === 'string') {
              return this.value.split(',')[0];
            } else {
              return this.value; // Return the value as is if it's not a string
            }
          }
        },
        gridLineDashStyle: "Dash",
        gridLineColor: "#505050",
        lineColor: "#505050",
        gridLineWidth: 1,
        categories: xAxisCategories,
      },
      yAxis: {
        title: null,
        min: 0,
        startOnTick: true,
        scrollbar: {
          enabled: true
        },
        gridLineDashStyle: "Dash",
        gridLineColor: "#505050",
        labels: {
          style: {
            color: "#ffffff",
            opacity: 0.7,
            fontSize: '14px',
          },
          formatter: function () {
            if (dataFormat == '$') {
              return dataFormat + this.value.toLocaleString();
            } else if (dataFormat == '%') {
              return this.value + dataFormat;
            } else {
              return this.value;
            }
          }
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.3,
          borderWidth: 0,
        }
      },
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false, // Disable exporting functionality
        buttons: {
          contextButton: {
            enabled: false // Disable the context menu button
          }
        }
      },
      series: seriesData, // Set series data
      navigation: {
        buttonOptions: {
          theme: {
            padding: 5,
            fill: '#2D2D2D',
            'stroke-width': 1,
            stroke: '#DFDFDF26',
            r: 0,
            style: {
              color: '#FFFFFF',
              opacity: 1,
              fontSize: '14px',
            },
            states: {
              hover: {
                fill: 'silver',
                style: {
                  color: 'black'
                }
              },
            }
          },
        }
      },
      credits: {
        enabled: false
      },
    };
  }

  /**
   * Configure bubble chart here based on common configuration
   * @param seriesData
   */
  configureBubbleChart(seriesData: any[]): any {
    return {
      chart: {
        type: "packedbubble",
        backgroundColor: "#2D2D2D",
        height: 470,
      },
      title: {
        text: "Device",
        align: 'left',
        style: {
          color: 'white',
          fontFamily: 'Montserrat',
          fontSize: '18px',
          fontWeight: '700',
          opacity: 1,
        },
        x: 5,
        y: 30,
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        packedbubble: {
          minSize: "40%",
          maxSize: "200%",
          zMin: 0,
          zMax: 900,
          layoutAlgorithm: {
            gravitationalConstant: 0.05,
            splitSeries: false,
          },
          dataLabels: {
            enabled: true,
            style: {
              color: "white",
              fontSize: '20px',
              fontWeight: 'Bold',
              align: 'left',
            },
          },
          fillOpacity: 1
        } as ExtendedChart
      },
      exporting: {
        enabled: false
      },
      series: seriesData,
      credits: {
        enabled: false
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        // align: 'left',
        verticalAlign: 'bottom',
        itemMarginBottom: 0,
        itemStyle: {
          color: '#FFFFFF',
          opacity: 0.7,
          fontWeight: 'normal',
          fontSize: '14px',
        },
        itemHoverStyle: null,
      }
    };
  }

  /**
   * Configure map chart here based on common configuration
   * @param seriesData
   */
  configureMapChart(seriesData: any[]) {
    return {
      chart: {
        map: worldMap,
        proj4: proj4,
        backgroundColor: "transparent",
      },
      title: {
        text: null
      },
      mapNavigation: {
        enabled: true,
        enableButtons: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
        },
      },
      legend: {
        enabled: false,
      },
      colorAxis: {
        dataClasses: [
          {
            to: 1,
            color: '#3B3E40',
            opacity: 0.09
          },
        ]
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '<b>{point.name}</b>',
        backgroundColor: '#2D2D2D',
        style: {
          color: 'white'
        }
      },
      series: [
        {
          states: {
            hover: {
              color: '#3B3E40',
            },
          },
          dataLabels: {
            enabled: false,
          },
          allAreas: false,
          color: 'red',
          data: [['au', 1]],
        },
        {
          type: 'mappoint',
          name: 'Australia cities',
          dataLabels: {
            enabled: false,
            format: '{point.name}',
          },
          data: seriesData
        },
      ],
      credits: {
        enabled: false
      },
    }
  }
}
