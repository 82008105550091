export class AccountModel {
    id!: any;
    abn!: any;
    company_name!: any;
    website!: any;
    business_phone!: any;
    created_at!: any;
    modified_at!: any;
    status!: any;
    logo?: string;
    companyAddresses?: any;
    address_line_1?:any;
    address_line_2?:any;
    post_code?:any;
    state_region?:any;
    suburb?:any;
    town_city?:any;
    country?:any;
    trade_type_id?: any;
    defaultAddress: any;
}
