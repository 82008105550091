import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RequestParamModel } from '../models';
import { BaseService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class FAQService {
  public API_CONSTANTS = "faq";
  public API_CONSTANTS_FOR_CMS = 'cms-page';
  constructor(
    public baseService: BaseService
  ) { }

  getCMSPage(requestParamModel?: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANTS_FOR_CMS, requestParamModel).pipe(
      map<any, any>(response => {
        return response.body
      }, (error: any) => {
        return error;
      })
    )
  }

  getFaqList(requestParamModel?: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANTS, requestParamModel).pipe(
      map<any, any>(response => {
        return response.body
      }, (error: any) => {
        return error;
      })
    )
  }

  postFaq(body: any) {
    return this.baseService.patch(`${this.API_CONSTANTS}/${body.id}`, body).pipe(
      map<any, any>(response => {
        return response.data;
      }, (error: any) => {
        return error;
      })
    )
  }


}
