import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../../constants';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  /* ################### Variable Declaration Start ################### */
  currentYear: number = new Date().getFullYear();
  constants = Constants;
  s3BucketImageURL!: any;
  /* ################### Variable Declaration End ################### */

  /* ################### Constructor Declaration Start ################### */
  constructor(
    public router: Router,
    public renderer: Renderer2
  ) { }
  /* ################### Constructor Declaration End ################### */

  /************************************
  * Life cycle hooks start
  ************************************/
  ngOnInit(): void {
    this.s3BucketImageURL = Constants.S3_PRODUCT_RESOURCE_IMAGE_KIT_URL + '/WEBSITE_ASSET_STAGING';

    this.renderer.addClass(document.body, 'not-found');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'not-found');
  }
  /************************************
  * Life cycle hooks end
  ************************************/

  /************************************
  * Redirection functions start
  ************************************/

  onHeader() {
    this.renderer.removeClass(document.body, 'not-found');
    this.router.navigate(['/']);
  }

  /************************************
  * Redirection functions end
  ************************************/

}
