import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ArticleModel, CommonCategoryModel, PaginationModel, RequestParamModel } from '../models';
import { BaseService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  public API_CONSTANT = "article";
  public API_CONSTANT_FOR_CATEGORY = 'article-category'
  constructor(
    private baseService: BaseService
  ) { }

  getArticles(requestParaModel?: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT, requestParaModel).pipe(
      map<any, any>(response => {
        const contactList = new PaginationModel<ArticleModel>();
        const headers = response.headers;
        contactList.setHeaders(headers);
        contactList.data = response.body.data as ArticleModel[];
        return contactList;
      }, (error: any) => {

      })
    )
  }

  getArticlesById(id: number, requestParaModel?: RequestParamModel) {
    return this.baseService.get(`${this.API_CONSTANT}/${id}`, requestParaModel).pipe(
      map<any, any>(response => {
        return response.body;
      }, (error: any) => {
        return error;
      })
    )
  }

  saveArticles(body: any) {
    if (body.id) {
      return this.baseService.patch(`${this.API_CONSTANT}/${body.id}`, body).pipe(
        map<any, any>(response => {
          return response.body;
        }, (error: any) => {
          return error;
        })
      )
    } else {
      return this.baseService.post(`${this.API_CONSTANT}`, body).pipe(
        map<any, any>(response => {
          return response.body;
        }, (error: any) => {
          return error;
        })
      )
    }
  }

  deleteArticles(id: any) {
    return this.baseService.delete(`${this.API_CONSTANT}/${id}`).pipe
      (map<any, any>(response => {
        return response
      }, (error: any) => {
        return error;
      }))
  }

  //#region BLOG CATEGORY ACTION
  getCategoryList(requestParamModel: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT_FOR_CATEGORY, requestParamModel).pipe
      (map<any, any>(response => {
        const accountList = new PaginationModel<CommonCategoryModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as CommonCategoryModel[];
        return accountList;
      }, (error: any) => {
        return error
      }))
  }

  postCategory(data: any) {
    if (data.id) {
      return this.baseService.patch(this.API_CONSTANT_FOR_CATEGORY + '/' + data.id, data).pipe(
        map<any, any>(response => {
          return response;
        }, (error: any) => {
          return error;
        })
      )
    } else {
      return this.baseService.post(this.API_CONSTANT_FOR_CATEGORY, data).pipe(
        map<any, any>(response => {
          return response;
        }, (error: any) => {
          return error;
        })
      )
    }

  }

  deleteCategory(id: any) {
    return this.baseService.delete(this.API_CONSTANT_FOR_CATEGORY + '/' + id).pipe
      (map<any, any>(response => {
        return response;
      }, (error: any) => {
        return error;
      }))
  }
  //#endregion

}
