import { Injectable } from '@angular/core';

@Injectable()
export class BackendThemeOptions {

    // Profile picture
    profilePicture = '';
    // Header Options
    isHeaderShow = false;
    // Right Sidebar
    rightSidebarAccountLoader = false;
    rightSidebar = false;
    redirectUrl = '';
    collectionAndSizeSelection = false;

}
