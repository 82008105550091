import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CommonCategoryModel, PaginationModel, RequestParamModel } from '../models';
import { BlogModel } from '../models/blog.model';
import { BaseService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  public API_CONSTANT = "blog";
  public API_CONSTANT_FOR_CATEGORY = 'blog-category'
  constructor(private baseService: BaseService) { }

  getBlogs(requestParaModel?: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT, requestParaModel).pipe(
      map<any, any>(response => {
        const contactList = new PaginationModel<BlogModel>();
        const headers = response.headers;
        contactList.setHeaders(headers);
        contactList.data = response.body.data as BlogModel[];
        return contactList;
      }, (error: any) => {

      })
    )
  }

  getBlogById(id: number, requestParaModel?: RequestParamModel) {
    return this.baseService.get(`${this.API_CONSTANT}/${id}`, requestParaModel).pipe(
      map<any, any>(response => {
        return response.body;
      }, (error: any) => {
        return error;
      })
    )
  }

  saveBlogs(body: any) {
    if (body?.id) {
      return this.baseService.patch(`${this.API_CONSTANT}/${body.id}`, body).pipe(
        map<any, any>(response => {
          return response;
        }, (error: any) => {
          return error;
        })
      )
    } else {
      return this.baseService.uploadPost(`${this.API_CONSTANT}`, body).pipe(
        map<any, any>(response => {
          return response.body;
        }, (error: any) => {
          return error;
        })
      )
    }
  }

  deleteBlog(id: any) {
    return this.baseService.delete(`${this.API_CONSTANT}/${id}`).pipe
      (map<any, any>(response => {
        return response.body
      }, (error: any) => {
        return error;
      }))
  }


  saveBlogImages(body: any) {
    return this.baseService.uploadPost(`${this.API_CONSTANT}/upload`, body).pipe(
      map<any, any>(response => {
        return response
      }, (error: any) => {
        return error;
      })
    )
  }

  //#region BLOG CATEGORY ACTION
  getCategoryList(requestParamModel: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT_FOR_CATEGORY, requestParamModel).pipe
      (map<any, any>(response => {
        const accountList = new PaginationModel<CommonCategoryModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as CommonCategoryModel[];
        return accountList;
      }, (error: any) => {
        return error
      }))
  }

  postCategory(data: any) {
    if (data.id) {
      return this.baseService.patch(this.API_CONSTANT_FOR_CATEGORY + '/' + data.id, data).pipe(
        map<any, any>(response => {
          return response;
        }, (error: any) => {
          return error;
        })
      )
    } else {
      return this.baseService.post(this.API_CONSTANT_FOR_CATEGORY, data).pipe(
        map<any, any>(response => {
          return response;
        }, (error: any) => {
          return error;
        })
      )
    }

  }

  deleteCategory(id: any) {
    return this.baseService.delete(this.API_CONSTANT_FOR_CATEGORY + '/' + id).pipe
      (map<any, any>(response => {
        return response;
      }, (error: any) => {
        return error;
      }))
  }
  //#endregion

}
