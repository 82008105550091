import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

/**
 * Title Service
 * This service has been used to set the title of the page
 */
export class TitleService {

  //#region Constructor

  constructor(private angularTitleService: Title, private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var title = this.getTitle(router.routerState, router.routerState.root).join('-');
        this.angularTitleService.setTitle(title);
      }
    });
  }
  //#endregion

  //#region Functions

  /**
   * Get Title
   * @param state 
   * @param parent 
   */
  getTitle(state: any, parent: any): any {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  //#endregion

}
