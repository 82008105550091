import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  visible: boolean;
  showonlyHeader?: boolean;
  routerArray = [];

  constructor() { this.visible = false; }

  hide() { this.visible = false; }

  show() { this.visible = true; }

  toggle() { this.visible = !this.visible; }

  showHeader() {
    this.showonlyHeader = true;
    this.visible = false;
  }
}
