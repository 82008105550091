import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ProductImportLogModel, ProductImportWarningModel, ProductModel, RequestParamModel} from '../models';
import {PaginationModel} from '../models';
import {BaseService} from '../services';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private baseService: BaseService) {
  }

  // get all user list
  getProduct(requestParamModel: RequestParamModel): any {
    return this.baseService.get('product', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<ProductModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ProductModel[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

  getProductLog(requestParamModel: RequestParamModel): any {
    return this.baseService.get('product-import', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<ProductModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ProductModel[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

  getTempProduct(requestParamModel: RequestParamModel): any {
    return this.baseService.get('tmp-product', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<ProductModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ProductModel[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

  //Product import detail
  getProductImportDetail(id: any): any {
    return this.baseService.get('product-import/' + id)
      .pipe(map<any, any>(response => {
        const accountList = response.body.data as ProductImportLogModel;
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

  //Product import detail
  getProductAccessories(requestParam: RequestParamModel): any {
    return this.baseService.get('product-accessories', requestParam)
      .pipe(map<any, any>(response => {
        const accountList = response.body.data as any;
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

  getImportProductWarnning(requestParamModel: RequestParamModel): any {
    return this.baseService.get('product-import-warning', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<ProductImportWarningModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ProductImportWarningModel[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

  //Product import detail
  getShippingImportDetail(id: any): any {
    return this.baseService.get('shipping-import/' + id)
      .pipe(map<any, any>(response => {
        const accountList = response.body.data as any;
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

  getCollectionImportDetail(id: any): any {
    return this.baseService.get('collection-import/' + id)
      .pipe(map<any, any>(response => {
        const accountList = response.body.data as any;
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

  // get single user details.
  getProductDetails(accountId: number, requestParamModel: RequestParamModel): Observable<ProductModel> {
    if (accountId !== 0) {
      return this.baseService.get('product/' + accountId, requestParamModel)
        .pipe(map<any, ProductModel>(response => {
          return response.body.data as ProductModel;
        }, (err: any) => {
          return err;
        }));
    } else {
      return this.baseService.get('product', requestParamModel)
        .pipe(map<any, ProductModel>(response => {
          return response.body.data[0] as ProductModel;
        }, (err: any) => {
          return err;
        }));
    }
  }

  getProductResourceUrl(id: any) {
    return this.baseService.get('product/download-resource/' + id).pipe(map(response => {
      return response.body;
    }, (error: any) => {
      return error;
    }))
  }

  // get single user details.
  getProductImportDetails(productImportId: number): Observable<ProductImportLogModel> {
    return this.baseService.get('product-import/' + productImportId)
      .pipe(map<any, ProductImportLogModel>(response => {
        return response.body.data as ProductImportLogModel;
      }, (err: any) => {
        return err;
      }));
  }

  // save user if new then add if old then update
  saveProduct(accountModel: any): Observable<number> {
    if (accountModel.id !== undefined) {
      return this.baseService.patch('product/' + accountModel.id, accountModel)
        .pipe(map<any, number>(response => {
            accountModel = response.data as ProductModel;
            return accountModel.id;
          }, (err: any) => {
            return err;
          })
        );
    } else {
      return this.baseService.post('product', accountModel)
        .pipe(map<any, number>(response => {
            accountModel = response.data as ProductModel;
            return accountModel.id;
          }, (err: any) => {
            return err;
          })
        );
    }
  }

  makeProductAsFeature(accountModel: any) {
    // save user if new then add if old then update
    return this.baseService.post('product/set-feature/' + accountModel.id, accountModel)
      .pipe(map<any, number>(response => {
          return response.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  makeProductAsAllInOne(model: any) {
    // save user if new then add if old then update
    return this.baseService.post('product/set-product-as-all-in-one', model)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  applyProduct(model: any) {
    // save user if new then add if old then update
    return this.baseService.post('product-import/apply', model)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  deleteProduct(_id: any) {
    return this.baseService.delete('product/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  uploadProductsheet(requestModel: any): Observable<any> {
    return this.baseService.uploadPost('product-import', requestModel)
      .pipe(map<any, any>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  uploadShippingSheet(requestModel: any): Observable<any> {
    return this.baseService.uploadPost('shipping-import', requestModel)
      .pipe(map<any, any>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  uploadCollectionSheet(requestModel: any): Observable<any> {
    return this.baseService.uploadPost('collection-import', requestModel)
      .pipe(map<any, any>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  updateProductOrder(requestModel: any): Observable<any> {
    return this.baseService.post('product-image/update-order', requestModel)
      .pipe(map<any, any>(response => {
          // return responseModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  // get product accessories details.
  getAccessoriesFormatDetails(productID: number): any {
    return this.baseService.get('product/get-accessories-formats?product_id=' + productID)
      .pipe(map<any, any>(response => {
        const productList = new PaginationModel<ProductModel>();
        const headers = response.headers;
        productList.setHeaders(headers);
        productList.data = response.body.data as ProductModel[];
        return productList;
      }, (err: any) => {
        return err;
      }));
  }

  getFilterAccessoriesProductDropdown(requestParamModel: RequestParamModel) {
    return this.baseService.get('product/filter-accessories-dropdown', requestParamModel)
      .pipe(map<any, any>(response => {
        const productList = response.body.data as any[];
        return productList;
      }, (err: any) => {
        return err;
      }));
  }

  // get product details.
  getDetails(productID: number, requestParamModel?: RequestParamModel): Observable<ProductModel> {
    return this.baseService.get('product/' + productID, requestParamModel)
      .pipe(map<any, ProductModel>(response => {
        return response.body.data as ProductModel;
      }, (err: any) => {
        return err;
      }));
  }

  // get product accessories details.
  getAccessoriesTypeDetails(productID: number): any {
    return this.baseService.get('product/get-accessories-types?product_id=' + productID)
      .pipe(map<any, any>(response => {
        const productList = new PaginationModel<any>();
        const headers = response.headers;
        productList.setHeaders(headers);
        productList.data = response.body.data as any[];
        return productList;
      }, (err: any) => {
        return err;
      }));
  }

  getFilterAccessoriesTypeDropdown(requestParamModel: RequestParamModel) {
    return this.baseService.get('product/filter-accessories-types-dropdown', requestParamModel)
      .pipe(map<any, any>(response => {
        const productList = response.body.data as any[];
        return productList;
      }, (err: any) => {
        return err;
      }));
  }

  download(bodyData: any) {
    return this.baseService.post('product/download', bodyData)
      .pipe(map<any, any>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  // download product sizing table
  downloadProductSizingTable(productID: number, userID: any, typeID :number): any {
    return this.baseService.get('product/download-format-size-table?product_id=' + productID + '&frontend_user_id=' + userID + '&product_type_id=' + typeID)
      .pipe(map<any, any>(response => {
        return response.body.data;
      }, (err: any) => {
        return err;
      }));
  }

  downloadFileContent(bodyData: any) {
    return this.baseService.getDownloadFileContent(`product/download-resource?id=${bodyData.id}&type=${bodyData.type}`);
  }

  generateSticker(productID: number): any {
    return this.baseService.get('product/generate-sticker/' + productID)
      .pipe(map<any, any>(response => {
        return response.body.data;
      }, (err: any) => {
        return err;
      }));
  }

  checkProductPreviouslyOrdered(productID: number, frontendUserID: number, requestParamModel?: RequestParamModel): Observable<ProductModel> {
    return this.baseService.get('product/check-product-previously-ordered/' + productID + '?frontend_user_id=' + frontendUserID, requestParamModel)
      .pipe(map<any, ProductModel>(response => {
        return response.body.data as ProductModel;
      }, (err: any) => {
        return err;
      }));
  }

  getConsumableCalculators(model: any) {
    // save user if new then add if old then update
    return this.baseService.post('product/consumable-calculation', model)
      .pipe(map<any, number>(response => {
          return response.data;
        }, (err: any) => {
          return err;
        })
      );
  }


  getComapnyName(requestParamModel: RequestParamModel): any {
    return this.baseService.get('product', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<ProductModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ProductModel[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

  // Unleashed Product Price Sync
  unleashedProductPriceSync(requestParamModel: RequestParamModel): any {
    return this.baseService.get('product/sync-price-from-unleashed', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<ProductModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ProductModel[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

    // Get all sample packaging box list
    getSamplePackingBox(requestParamModel: RequestParamModel): any {
      return this.baseService.get('sample-packing-box', requestParamModel)
        .pipe(map<any, any>(response => {
          const accountList = new PaginationModel<ProductModel>();
          const headers = response.headers;
          accountList.setHeaders(headers);
          accountList.data = response.body.data as ProductModel[];
          return accountList;
        }, (err: any) => {
          return err;
        }));
    }


    getProductCollectionList(requestParamModel: RequestParamModel): any {
      return this.baseService.get('product', requestParamModel)
        .pipe(map<any, any>(response => {
          const responseList = new PaginationModel<any>();
          const headers = response.headers;
          responseList.setHeaders(headers);
          responseList.data = response.body.data as any[];
          return responseList;
        }, (err: any) => {
          return err;
        }));
    }

}
