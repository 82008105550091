import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule, Meta} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AdminCoreModule} from "./admin-core/admin-core.module";
import {ToastrModule} from "ngx-toastr";
import {SharedModule} from "./shared/shared.module";
import {CommonModule} from "@angular/common";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {IMaskModule} from "angular-imask";
import { DndModule } from 'ngx-drag-drop';

@NgModule({
  declarations: [
    AppComponent,
  ],

  imports: [
     BrowserModule,
    AppRoutingModule,
    AdminCoreModule,
    CommonModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    ToastrModule.forRoot(
      {maxOpened: 1, autoDismiss: true, preventDuplicates: true,}
    ),
    NgbModule,
    IMaskModule,
    SharedModule,
    DndModule,
    // AngularFireMessagingModule
    // AngularFireModule.initializeApp(environment.firebase),
],
  providers: [
    Meta
  ],
  bootstrap: [AppComponent],
  exports: [],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
}
