export class ContactsModel {
    id: any;
    created_at: any;
    name!: string;
    company_name!: string;
    role!: any;
    status!: string;
    last_activity: any;
    last_sample: any;
    last_quote: any;
    last_order: any;
    total_ordered: any;
    assigned_to: any;
    first_name: any;
    last_name: any;
    mobile: any;
    email: any;
    company_id: any;
    company: any;
    assignedToAdmin: any;
    assigned_to_admin: any;
    is_company_verified: any;
    contact_id: any;
    frontend_user_role: any;
    contact_name: any;
    contact_email: any;
    companyAddresses: any;
    frontendUserAddresses: any;
    business_role?: any;
    status_declined_reason?:string;
    status_inactive_reason?:string;
    email_template_title?: any;
    email_template_body?: any;
    is_mail_send?: boolean;
    business_role_id?: any;
    job_title?: any;
    frontendUserCompanyDefaultAddresses: any;
    is_send_copy_to_sales_representative: any;
    bpay_biller_code: any;
    bpay_reference: any;
    pendingTasks?: any;
}
