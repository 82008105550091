import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AlertService, HelperService, SubscribersService} from '@coreservices';
import {Constants} from 'src/app/admin-core/constants';
import {NotificationService} from 'src/app/admin-core/apiservices/notification.service';
import {RequestParamModel} from 'src/app/admin-core/models/request-params.model';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';
import {Router} from '@angular/router';
import { settings } from 'cluster';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.css']
})
export class NotificationSidebarComponent implements OnInit {
  private sidebarSubscription: Subscription;
  private resetSidebarSubscription: Subscription;
  isSidebarLoading = false;
  toggleStyle: boolean = false;
  s3BucketImageURL: any
  createClass: boolean = false;
  detailClass: boolean = false;
  toggleVariable: boolean = false;
  constants = Constants;
  isAllNotificationRead: boolean = true;
  requestParamModel = new RequestParamModel();
  userProfileDetail: any;
  userNotifications: any;
  isToggledOn = true;
  isLoading: boolean = false;
  unReadNotificationCount = 0;

  constructor(private alertService: AlertService, private router: Router, private changeDetection: ChangeDetectorRef, private subscribersService: SubscribersService, public helperFunction: HelperService, private notificationService: NotificationService) {
    this.sidebarSubscription = this.subscribersService.notificationSidebar.subscribe((options) => {
      this.toggleStyle = options;
    });

    this.resetSidebarSubscription = this.subscribersService.resetNotificationSidebar.subscribe((options) => {
      if (options) {
      }
    });
    let userProfile = localStorage.getItem('admin_user');
    this.userProfileDetail = JSON.parse(userProfile);
  }

   /************************************
   * Life cycle hooks start
   ************************************/

  ngOnInit(): void {
    this.s3BucketImageURL = Constants.S3_PRODUCT_RESOURCE_IMAGE_KIT_URL + '/WEBSITE_ASSET_STAGING';
    this.getNotifications();
  }

  ngOnDestroy(): void {
    this.sidebarSubscription.unsubscribe();
    this.resetSidebarSubscription.unsubscribe();
  }

   /************************************
   * Life cycle hooks end
   ************************************/

   /************************************
   * Notification fetching and display start
   ************************************/

  getNotifications() {
    this.isLoading = true;
    this.requestParamModel.expands = ['fromUser', 'toUser', 'note.quote','company','quoteProduct.quote'];
    this.requestParamModel.removeDefaultFilter('to_user_id');
    this.requestParamModel.addDefaultFilter('to_user_id', this.userProfileDetail.id, 'number');
    delete this.requestParamModel.pageSize;
    this.requestParamModel.sortChange('id', 'desc')
    if (this.isToggledOn) {
      this.requestParamModel.removeDefaultFilter('status');
      this.requestParamModel.addDefaultFilter('status', Constants.NOTIFICATION_STATUS_INACTIVE, 'number');
    }
   this.notificationService.getUserNotifications(this.requestParamModel).subscribe((dataList) => {
      this.userNotifications = dataList.data;
       this.userNotifications = this.userNotifications.slice(0, 8);
      this.isDisplayMarkAllAsReadText();
      this.isLoading = false;
    }, (error: HttpErrorResponse) => {
    });
  }

  /************************************
   * Notification fetching and display end
   ************************************/

  /************************************
  * Sidebar management start
  ************************************/

  closeAddItemSidebar(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.toggleStyle = !this.toggleStyle;
    this.subscribersService.notificationSidebarToggle(false);
    setTimeout(() => {
      this.subscribersService.emitNotificationSidebarToggle(false);
    }, 500);
  }

  /************************************
  * Sidebar management start
  ************************************/


  toggleMarkAsRead(event: any, notification: any) {
   let isChecked = event.target.checked;
    let json = {
        status: isChecked ? Constants.NOTIFICATION_STATUS_ACTIVE : Constants.NOTIFICATION_STATUS_INACTIVE,
        type: notification.note_id ? Constants.NOTE_NOTIFICATION : Constants.QUOTE_PRODUCT_NOTIFICATION
    };

    this.notificationService.markNotificationAsRead(json, notification.id).subscribe((response: any) => {
        isChecked ? this.alertService.success(Constants.NOTIFICATION_MARKED_AS_READ_SUCCESS_MESSAGE) : this.alertService.success(Constants.NOTIFICATION_MARKED_AS_UNREAD_SUCCESS_MESSAGE);

        let notificationToUpdate = this.userNotifications.find(message => message.id === notification.id);
        if (notificationToUpdate) {
            notificationToUpdate.status = response.data.status;
        }
        if (this.isToggledOn) {
            this.userNotifications = this.userNotifications.filter(item => item.id !== notification.id);
        }
        this.subscribersService.updateUnreadNotification(this.unReadNotificationCount.toString());
        this.isDisplayMarkAllAsReadText();
    });
}


  isDisplayMarkAllAsReadText() {
    let isAllNotificationRead = this.userNotifications.every(item => item.status == Constants.NOTIFICATION_STATUS_ACTIVE);
     this.updateNotificationBellIconStatus(!isAllNotificationRead);
  }

  toggleMarkAllAsRead() {
    this.isAllNotificationRead = false;

    let json = {
      to_user_id: this.userProfileDetail.id
    }

     this.notificationService.markAllNotificationsAsRead(json).subscribe(() => {
      this.updateNotificationBellIconStatus(this.isAllNotificationRead);

     this.alertService.success(Constants.ALL_NOTIFICATION_MARKED_AS_READ_SUCCESS_MESSAGE);
      if (this.isToggledOn) {
        this.userNotifications = [];
      } else {
        this.userNotifications.forEach(notification => notification.status = Constants.NOTIFICATION_STATUS_ACTIVE);
      }
    })
    this.subscribersService.updateUnreadNotification(this.unReadNotificationCount.toString());
  }

  updateNotificationBellIconStatus(isRead: boolean) {
    this.isAllNotificationRead = isRead;
    this.subscribersService.updateNotificationBellIcon(this.isAllNotificationRead);
    this.subscribersService.updateUnreadNotification(this.unReadNotificationCount.toString())
  }

  onChangeToggleIcon() {
    this.isToggledOn = !this.isToggledOn;
    this.getNotifications();
  }

  /************************************
  * Sidebar management end
  ************************************/

  /************************************
  * Helper functions start
  ************************************/

  getUserImage(profile_picture: any): string {
    const getKitPath = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL;
    if (profile_picture != '' && profile_picture != null) {
      return getKitPath + '/USER_PROFILE_PICTURE/' + profile_picture + this.constants.IMAGE_SIZE_50_X_50;
    }
    return null;
  }

  getNotificationSenderName(fromUser: any) {
    if (fromUser) {
      let firstName = fromUser.first_name ? fromUser.first_name : '';
      let lastName = fromUser.last_name ? fromUser.last_name : '';

      let notificationSenderFullName = `${firstName} ${lastName}`;
      return notificationSenderFullName;
    }
    return '';
  }

  setNotificationStatus(status: number): boolean {
    if (status && status == Constants.NOTIFICATION_STATUS_ACTIVE) {
      return true
    }
    return false
  }

  seeAllNotifications() {
    this.router.navigate(['notifications']);
    this.closeAddItemSidebar();
  }

  redirectToModule(notification) {
    if(notification.note_id)
    {
    if (!notification || !notification.note) {
        return;
      }
     const note = notification.note;
    const id = note.quote_id;
    const type = note.quote ? note.quote.type : null;
     if (type) {
        let route = '';
        switch (type) {
            case Constants.ORDER_TYPE_SAMPLE_SHORT:
                route = 'sample-requests/view/';
                break;
            case Constants.ORDER_TYPE_QUOTE_SHORT:
                route = 'quotes/view/';
                break;
            case Constants.ORDER_TYPE_ORDER_SHORT:
                route = 'orders/view/';
                break;
            default:
                // Handle unknown type scenario if needed
                break;
        }
      if (route) {
            this.router.navigate([route, id]);
        }
    }

    else {
        if (note.company_id !== null) {
            this.router.navigate(['accounts/', note.company_id]);
        } else {
            this.router.navigate(['contacts/', note.frontend_user_id]);
        }
    }
       this.closeAddItemSidebar();
    }
    if(notification.quote_product_id)
    {
       if (!notification || !notification.message) {
        return;
      }
      const note = notification.quoteProduct;
      const id = note.quote_id;
      const type = note.quote ? note.quote.type : null;
      if (type) {
        let route = '';
        switch (type) {
            case Constants.ORDER_TYPE_SAMPLE_SHORT:
                route = 'sample-requests/view/';
                break;
            case Constants.ORDER_TYPE_QUOTE_SHORT:
                route = 'quotes/view/';
                break;
            case Constants.ORDER_TYPE_ORDER_SHORT:
                route = 'orders/view/';
                break;
            default:
                // Handle unknown type scenario if needed
                break;
        }
      if (route) {
            this.router.navigate([route, id]);
            this.closeAddItemSidebar();
        }
     }
      }

    }

  /************************************
  * Helper functions end
  ************************************/

}
