import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PaginationModel, ProjectModel, RequestParamModel } from '../models';
import { BaseService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    public baseService: BaseService
  ) { }
  public API_CONSTANT = "project";

  getProjects(requestParamsModel?: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT, requestParamsModel ? requestParamsModel : new RequestParamModel()).pipe(
      map<any, any>(response => {
        const accountList = new PaginationModel<ProjectModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ProjectModel[];
        return accountList;
      }, (error: any) => {
        return error;
      })
    )
  }

  saveProjects(body: any) {
    if (body?.id) {
      return this.baseService.patch(`${this.API_CONSTANT}/${body?.id}`, body).pipe(
        map<any, any>(response => {
          return response.body;
        }, ((error: any) => {
          return error;
        }))
      )
    } else {
      return this.baseService.uploadPost(this.API_CONSTANT, body).pipe(
        map<any, any>(response => {
          return response.body;
        }, ((error: any) => {
          return error;
        }))
      )
    }
  }

  getProjectById(id: number, requestParamModel?: RequestParamModel) {
    return this.baseService.get(`${this.API_CONSTANT}/${id}`, requestParamModel).pipe(
      map<any, any>(response => {
        return response.body.data
      }, (error: any) => {
        return error;
      })
    )
  }

  deleteProject(id: number) {
    return this.baseService.delete(`${this.API_CONSTANT}/${id}`).pipe(
      map<any, any>(response => {
        return response;
      }, (error: any) => {
        return error;
      })
    )
  }

  saveProjectImage(body: any) {
    return this.baseService.uploadPost(this.API_CONSTANT + '/upload', body).pipe(map<any, any>(response => {
      return response
    }), (error: any) => {
      return error;
    })
  }

  saveProjectProducts(data: any) {
    return this.baseService.post(this.API_CONSTANT, data).pipe(
      map<any, any>(response => {
        return response.body
      }, (error: any) => {
        return error;
      })
    )
  }

  deleteProjectImage(body: any) {
    return this.baseService.delete(this.API_CONSTANT + '/delete-image/' + body.id + '?field=' + body.image).pipe(map<any, any>(data => {
      return data
    }), (error: any) => {
      return error;
    })
  }


}
