import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FamilyModel, ProductImportLogModel, ProductImportWarningModel, RequestParamModel } from '../models';
import { PaginationModel } from '../models';
import { BaseService } from '../services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormatModel } from '@coremodels';

@Injectable({
  providedIn: 'root'
})
export class FormatService {

  constructor(private baseService: BaseService) { }
    // get all user list
    getFormat(requestParamModel: RequestParamModel): any {
      return this.baseService.get('format', requestParamModel)
        .pipe(map<any, any>(response => {
          const accountList = new PaginationModel<FormatModel>();
          const headers = response.headers;
          accountList.setHeaders(headers);
          accountList.data = response.body.data as FormatModel[];
          return accountList;
        }, (err: any) => {
          return err;
        }));
    }
}
