import {EventEmitter, Injectable} from '@angular/core';
import {UserModel} from '../../models/user.model';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {HelperService} from '../helpers/helper.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Subscribers service.
 * This is the service which is for those variables which needs to be global.
 * When there is a change in the variable then the component will call this service method
 * and change the variable value.
 * We need to subscribe this service wherver it needs.
 */
export class SubscribersService {

  //#region Variables
  changed_username: Subject<string> = new Subject<string>();
  userLogout: Subject<Boolean> = new Subject<Boolean>();
  _profileModel = new BehaviorSubject<UserModel>(new UserModel());
  profileModel = this._profileModel.asObservable();
  headDrawer: Subject<boolean> = new Subject<boolean>();
  categoryAndSizeSelection: Subject<boolean> = new Subject<boolean>();
  updateSampleOrderCount: Subject<boolean> = new Subject<boolean>();
  updatePendingAccountCount: Subject<boolean> = new Subject<boolean>();
  updateTaskCount: Subject<string> = new Subject<string>();
  updateUnReadNotificationCount : Subject<string> = new Subject<string>();
  updateContactEmail: Subject<string> = new Subject<string>();
  updateContactAssignUser: Subject<number> = new Subject<number>();
  addItemSidebarClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  notificationSidebarClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  addItemSidebar: Subject<boolean> = new Subject<boolean>();
  notificationSidebar: Subject<boolean> = new Subject<boolean>();
  accessorySizeChange: EventEmitter<number> = new EventEmitter<number>();
  resetAddItemSidebar: Subject<boolean> = new Subject<boolean>();
  resetNotificationSidebar: Subject<boolean> = new Subject<boolean>();
  updateDateRange: ReplaySubject<{ start_date: string, end_date: string }> = new ReplaySubject<{ start_date: string, end_date: string }>(1);
  topLocationByRegionData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private moreActivitiesClicked = new Subject<void>();
  moreActivitiesClicked$ = this.moreActivitiesClicked.asObservable();
  private moreProductsClicked = new Subject<void>();
  moreProductsClicked$ = this.moreProductsClicked.asObservable();
  private moreOrderProductsClicked = new Subject<void>();
  moreOrderProductsClicked$ = this.moreOrderProductsClicked.asObservable();
  private moreProductsByType = new Subject<string>();
  moreProductsByType$ = this.moreProductsByType.asObservable();
  private familyData = new Subject<void>();
  familyData$ = this.familyData.asObservable();
  private formatData = new Subject<void>();
  formatData$ = this.formatData.asObservable();
  private finishData = new Subject<void>();
  finishData$ = this.finishData.asObservable();
  updateChartType: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isHidePerformerFields: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isUnReadNotification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isNotificationSidebarOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private partialPaymentSubject = new Subject<any>();
  emitPartialPaymentData$ = this.partialPaymentSubject.asObservable();
  sampleImageDetails: BehaviorSubject<{ quote_product_id?:any, id?: string, original_file_name?: string }> = new BehaviorSubject<{ quote_product_id?:any, id?: string, original_file_name?: string }>(null);
  private moreOrdersClicked = new Subject<void>();
  moreOrdersClicked$ = this.moreOrdersClicked.asObservable();
  isClientResponded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  //#endregion

  //#region Costructor
  constructor(private helperService: HelperService) {
    this.loadProfile();
  }

  //#endregion

  //#region functions
  /**
   * Change the user name
   * @param name
   */
  changeUserName(name: any) {
    this.changed_username.next(name);
  }

  loadProfile() {
    const profile = localStorage.getItem('admin_user');
    if (profile !== null && profile !== undefined) {
      let userDetail: any = JSON.parse(profile);
      this._profileModel.next(userDetail);
    }
  }

  headDrawerToggle(flag: boolean) {
    this.headDrawer.next(flag);
  }

  ngOnDestory() {
    this._profileModel.unsubscribe();
    this._profileModel.complete();
  }

  leftDrawerSurfaceToggle(flag: boolean) {
    this.categoryAndSizeSelection.next(flag);
  }

  updateSampleOrderCountToggle(flag: boolean) {
    this.updateSampleOrderCount.next(flag);
  }

  updatePendingAccountCountToggle(flag: boolean) {
    this.updatePendingAccountCount.next(flag);
  }

  updateTaskCountToggle(flag: string) {
    this.updateTaskCount.next(flag);
  }

  updateUnreadNotification(flag: string) {
    this.updateUnReadNotificationCount.next(flag);
  }

  updateContactEmailData(data: string) {
    this.updateContactEmail.next(data);
  }

  updateContactAssignUserData(data: number) {
    this.updateContactAssignUser.next(data);
  }

  emitAddItemSidebarClick(eventData: boolean) {
    this.addItemSidebarClick.emit(eventData);
  }

  addItemSidebarToggle(flag: boolean) {
    this.addItemSidebar.next(flag);
  }

  /************************************
   * change accessory size
   ************************************/
  resetAddItemSidebarToggle(flag: boolean) {
    this.resetAddItemSidebar.next(flag);
  }

  emitAccessorySizeChange(eventData: number) {
    this.accessorySizeChange.emit(eventData);
  }

  userLoggedOut(flag: boolean) {
    this.userLogout.next(flag);
  }

  notificationSidebarToggle(flag: boolean) {
    this.notificationSidebar.next(flag);
  }

  emitNotificationSidebarToggle(eventData: boolean) {
    this.notificationSidebarClick.emit(eventData);
  }

  resetNotificationSidebarToggle(flag: boolean) {
    this.resetNotificationSidebar.next(flag);
  }

  updateDateRangeData(startDate: string, endDate: string) {
    this.updateDateRange.next({start_date: startDate, end_date: endDate});
  }

  emitTopLocationByRegionData(regionData) {
    this.topLocationByRegionData.next(regionData)
  }

  emitMoreActivitiesClicked() {
    this.moreActivitiesClicked.next();
  }

  emitMoreProductsClicked() {
    this.moreProductsClicked.next();
  }

  emitMoreOrderProductsClicked() {
    this.moreOrderProductsClicked.next()
  }

  emitFamilyData(familyData) {
    this.familyData.next(familyData);
  }

  emitFormatData(formatData) {
    this.formatData.next(formatData);
  }

  emitFinishData(finishData) {
    this.finishData.next(finishData);
  }

  updateChartToggle(flag: boolean) {
    this.updateChartType.next(flag)
  }

  emitMoreProductsByType(flag: any) {
    this.moreProductsByType.next(flag);
  }

  emitHidePerformerFields(flag: boolean){
    this.isHidePerformerFields.next(flag)
  }

  emitPartialPaymentData(data: any) {
    this.partialPaymentSubject.next(data);
  }

  updateNotificationBellIcon(flag: boolean) {
    this.isUnReadNotification.next(flag)
  }

  emitNotificationSidebarStatus(flag: boolean) {
    this.isNotificationSidebarOpen.next(flag)
  }

  emitSampleImageDetails(data: any) {
    this.sampleImageDetails.next(data)
  }

  emitMoreOrdersClicked() {
    this.moreOrdersClicked.next();
  }

  emitClientResponse(flag: boolean) {
    this.isClientResponded.next(flag)
  }
}
