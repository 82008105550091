import {AfterViewInit, ChangeDetectorRef, Component, Renderer2} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import {Constants} from './admin-core/constants';
import {BackendThemeOptions} from "./admin-core/backend-theme-options";
import {SubscribersService} from "@coreservices";
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'gatherco';
  isLoading: boolean = false;
  shouldShowHeader: boolean = false;


  constructor(
    private renderer: Renderer2,
    private router: Router,
    public changeDetection: ChangeDetectorRef,
    public globals: BackendThemeOptions,
    public subscribersService: SubscribersService,
    private route: ActivatedRoute
  ) {
    router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.isLoading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.isLoading = false;
          var currentUrl = this.router.url;
          var keys = Object.keys(localStorage);

          Constants.LOCALSTORAGE_REMOVE_ARRAY.forEach(element => {
            if (!currentUrl.includes(element)) {
              for (var i = 0, key; key = keys[i]; i++) {
                if (key.includes(element)) {
                  localStorage.removeItem(key);
                }
              }
            }
          });

          if (event instanceof NavigationEnd) {
            // Access the current activated route
            const activatedRoute = this.route.firstChild;

            // Check if the route or any of its ancestors have a data property named 'hideHeader'
            const hideHeader = this.hasHideHeaderData(activatedRoute);
            this.shouldShowHeader = !hideHeader;
          }
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    // this.urlArray = this.route.url.split('/');
  }

  private hasHideHeaderData(route: ActivatedRoute | null): boolean {
    if (!(route instanceof ActivatedRoute) || route.snapshot.data && route.snapshot.data['hideHeader']) {
      return true;
    }

    if (route.firstChild) {
      return this.hasHideHeaderData(route.firstChild);
    }

    return false;
  }

  ngAfterViewInit() {
  }
}
