import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FamilyModel, MaterialModel, ProductImportLogModel, ProductImportWarningModel, RequestParamModel } from '../models';
import { PaginationModel } from '../models';
import { BaseService } from '../services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  constructor(private baseService: BaseService) { }
    // get all user list
    getMaterial(requestParamModel: RequestParamModel): any {
      return this.baseService.get('material', requestParamModel)
        .pipe(map<any, any>(response => {
          const accountList = new PaginationModel<MaterialModel>();
          const headers = response.headers;
          accountList.setHeaders(headers);
          accountList.data = response.body.data as MaterialModel[];
          return accountList;
        }, (err: any) => {
          return err;
        }));
    }
}
