import { Injectable } from '@angular/core';
import { AccountModel, ContactsModel, RequestParamModel } from '../models';
import { PaginationModel } from '../models';
import { BaseService } from '../services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ConsumablesService {

    constructor(
        private baseService: BaseService
    ) {
    }

    getConsumablesFinishList(requestParamModel: RequestParamModel): Observable<PaginationModel<ContactsModel>> {
        return this.baseService.get('consumables-finish', requestParamModel)
            .pipe(map<any, PaginationModel<ContactsModel>>(response => {
                const finishList = new PaginationModel<ContactsModel>();
                const headers = response.headers;
                finishList.setHeaders(headers);
                finishList.data = response.body.data as ContactsModel[];
                return finishList;
            }, (err: any) => {
                return err;
            }));
    }

    getConsumablesPriceList(requestParamModel: RequestParamModel): Observable<PaginationModel<ContactsModel>> {
        return this.baseService.get('consumables-prices', requestParamModel)
            .pipe(map<any, PaginationModel<ContactsModel>>(response => {
                const priceList = new PaginationModel<ContactsModel>();
                const headers = response.headers;
                priceList.setHeaders(headers);
                priceList.data = response.body.data as ContactsModel[];
                return priceList;
            }, (err: any) => {
                return err;
            }));
    }

    getConsumablesQuantityList(requestParamModel: RequestParamModel): Observable<PaginationModel<ContactsModel>> {
        return this.baseService.get('consumables-quantity', requestParamModel)
            .pipe(map<any, PaginationModel<ContactsModel>>(response => {
                const finishList = new PaginationModel<ContactsModel>();
                const headers = response.headers;
                finishList.setHeaders(headers);
                finishList.data = response.body.data as ContactsModel[];
                return finishList;
            }, (err: any) => {
                return err;
            }));
    }



    updateConsumablesQuantity(model: any): Observable<number> {
        return this.baseService.patch('consumables-quantity/' + model.id, model)
            .pipe(map<any, number>(response => {
                model = response.data as any;
                return model;
            }, (err: any) => {
                return err;
            })
            );
    }


    updateConsumablesPrices(model: any): Observable<number> {
        return this.baseService.patch('consumables-prices/' + model.id, model)
            .pipe(map<any, number>(response => {
                model = response.data as any;
                return model;
            }, (err: any) => {
                return err;
            })
            );
    }


    createConsumablesQuantity(model: any): Observable<number> {
        return this.baseService.post('consumables-quantity', model)
            .pipe(map<any, number>(response => {
                model = response.data as any;
                return model;
            }, (err: any) => {
                return err;
            })
            );
    }

    deleteConsumablesQuantity(model: any): Observable<number> {
        return this.baseService.delete('consumables-quantity/' + model.id)
            .pipe(map<any, number>(response => {
                model = response as any;
                return model;
            }, (err: any) => {
                return err;
            })
            );
    }
}
