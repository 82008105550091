import { Injectable } from '@angular/core';
import { BaseService } from "../services";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { PaginationModel, RequestParamModel } from '../models';
import { CategoryModel } from '@coremodels';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private baseService: BaseService) {
  }

  // Get Lists
  getLists(requestParamModel: RequestParamModel): any {
    return this.baseService.get('category', requestParamModel)
      .pipe(map<any, any>(response => {
        const responseList = new PaginationModel<CategoryModel>();
        const headers = response.headers;
        responseList.setHeaders(headers);
        responseList.data = response.body.data as CategoryModel[];
        return responseList;
      }, (err: any) => {
        return err;
      }));
  }

  // get details.
  getDetails(categoryID: number, requestParamModel?: RequestParamModel): Observable<CategoryModel> {
    return this.baseService.get('category/' + categoryID, requestParamModel)
      .pipe(map<any, CategoryModel>(response => {
        return response.body.data as CategoryModel;
      }, (err: any) => {
        return err;
      }));
  }

  // Get product category Lists
  getProductCategoryList(requestParamModel: RequestParamModel): any {
    return this.baseService.get('product-category', requestParamModel)
      .pipe(map<any, any>(response => {
        const responseList = new PaginationModel<CategoryModel>();
        const headers = response.headers;
        responseList.setHeaders(headers);
        responseList.data = response.body.data as CategoryModel[];
        return responseList;
      }, (err: any) => {
        return err;
      }));
  }

}
