import { Injectable } from '@angular/core';
import { LogisticType, PaginationModel, RequestParamModel } from '../models';
import { BaseService } from '../services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LogisticTypeService {

  constructor(public baseService: BaseService) { }

  getLogisticTypes(requestParamModel: RequestParamModel): any {
    return this.baseService.get('logistic-type', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<LogisticType>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as LogisticType[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }
}
