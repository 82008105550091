import {Injectable} from '@angular/core';
import {AccountModel, ContactsModel, QuoteOrderSampleModel, RequestParamModel, TradeTypeModel} from '../models';
import {PaginationModel} from '../models';
import {BaseService} from '../services';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ContactsService {

    constructor(private baseService: BaseService) {
    }

    // get all user list
    getContacts(requestParamModel: RequestParamModel): Observable<PaginationModel<ContactsModel>> {
        return this.baseService.get('contact', requestParamModel)
            .pipe(map<any, PaginationModel<ContactsModel>>(response => {
                const contactList = new PaginationModel<ContactsModel>();
                const headers = response.headers;
                contactList.setHeaders(headers);
                contactList.data = response.body.data as ContactsModel[];
                return contactList;
            }, (err: any) => {
                return err;
            }));
    }

    getContactsAll(requestParamModel: RequestParamModel): Observable<ContactsModel> {
        return this.baseService.get('contact', requestParamModel)
            .pipe(map<any, any>(response => {
                return response.body.data as ContactsModel[];
            }, (err: any) => {
                return err;
            }));
    }

    // get single user details.
    getContactdetail(contactId: number, requestParamModel: RequestParamModel): Observable<ContactsModel> {
        return this.baseService.get('contact/' + contactId, requestParamModel)
            .pipe(map<any, ContactsModel>(response => {
                return response.body.data as ContactsModel;
            }, (err: any) => {
                return err;
            }));
    }

    // save user if new then add if old then update
    saveContact(contactModel: any): Observable<number> {
        if (contactModel.id !== undefined) {
            return this.baseService.patch('contact/' + contactModel.id, contactModel)
                .pipe(map<any, number>(response => {
                        contactModel = response.data as ContactsModel;
                        return contactModel.id;
                    }, (err: any) => {
                        return err;
                    })
                );
        } else {
            return this.baseService.post('contact', contactModel)
                .pipe(map<any, number>(response => {
                        contactModel = response.data as ContactsModel;
                        return contactModel.id;
                    }, (err: any) => {
                        return err;
                    })
                );
        }
    }

    deleteContact(_id: any) {
        return this.baseService.delete('contact/' + _id)
            .pipe(map<any, number>(response => {
                    return response;
                }, (err: any) => {
                    return err;
                })
            );
    }

    // Get Trade Lists
    getTradeTypeLists(requestParamModel: RequestParamModel): any {
        return this.baseService.getWithoutToken('trade-type', requestParamModel)
            .pipe(map<any, any>(response => {
                const responseList = new PaginationModel<TradeTypeModel>();
                const headers = response.headers;
                responseList.setHeaders(headers);
                responseList.data = response.body.data as TradeTypeModel[];
                return responseList;
            }, (err: any) => {
                return err;
            }));
    }

    createUpdateContactAtGoogle(accountModel: any): Observable<number> {
        return this.baseService.post('contact/create-update-contact-at-google', accountModel)
            .pipe(map<any, number>(response => {
                    return response.data;
                }, (err: any) => {
                    return err;
                })
            );
    }

    // Get Trade Lists
    getBusinessRoles(requestParamModel: RequestParamModel): any {
        return this.baseService.get('business-role', requestParamModel)
            .pipe(map<any, any>(response => {
                const responseList = new PaginationModel<TradeTypeModel>();
                const headers = response.headers;
                responseList.setHeaders(headers);
                responseList.data = response.body.data as TradeTypeModel[];
                return responseList;
            }, (err: any) => {
                return err;
            }));
    }

    // save user if new then add if old then update
    saveContactAddress(accountModel: any): Observable<number> {
        return this.baseService.post('frontend-user-address', accountModel)
            .pipe(map<any, number>(response => {
                    accountModel = response.data as AccountModel;
                    return accountModel.id;
                }, (err: any) => {
                    return err;
                })
            );
    }

    updateContactAddress(model: any): Observable<number> {
        return this.baseService.patch('frontend-user-address/' + model.id, model)
            .pipe(map<any, number>(response => {
                    model = response.data as any;
                    return model.id;
                }, (err: any) => {
                    return err;
                })
            );
    }

    getNote(requestModel: any) {
        return this.baseService.get('note', requestModel)
            .pipe(map<any, any>(response => {
                    return response.body.data as any;
                }, (err: any) => {
                    return err;
                })
            );
    }

    addUpdateNote(requestModel: any) {
        if (requestModel.noteId) {
            return this.baseService.patch('note/' + requestModel.noteId, requestModel)
                .pipe(map<any, number>(response => {
                        requestModel = response.data as QuoteOrderSampleModel;
                        return requestModel;
                    }, (err: any) => {
                        return err;
                    })
                );
        } else {
            return this.baseService.post('note', requestModel)
                .pipe(map<any, number>(response => {
                        requestModel = response.data as QuoteOrderSampleModel;
                        return requestModel;
                    }, (err: any) => {
                        return err;
                    })
                );
        }
    }

    addUpdateNoteWithAttachment(requestModel: any,noteId?: any) {
        if (noteId) {
            return this.baseService.uploadPost(`note/upload/`+ noteId, requestModel).pipe(
                map<any, any>(response => {
                return response.data;
                }, (error: any) => {
                return error;
                })
            )
        } else {
            return this.baseService.uploadPost(`note`, requestModel).pipe(
                map<any, any>(response => {
                return response.data;
                }, (error: any) => {
                return error;
                })
            )
        }
    }



    deleteNote(_id: any) {
        return this.baseService.delete('note/' + _id)
            .pipe(map<any, number>(response => {
                return response;
                }, (err: any) => {
                    return err;
                })
            );
    }

    setCompanyAddressAsDefault(requestModel: any): Observable<number> {
        return this.baseService.post('contact/set-company-address-as-default', requestModel)
            .pipe(map<any, number>(response => {
                    return response.data;
                }, (err: any) => {
                    return err;
                })
            );
    }

    previewUploadFile(id: any) {
        return this.baseService.get('note/preview/' + id)
            .pipe(map<any, any>(response => {
                return response.body.data as any;
            }, (err: any) => {
                return err;
            }));
    }

    deleteSelectedPdfFile(_id: any) {
        return this.baseService.delete('note/delete-attachment/' + _id)
            .pipe(map<any, number>(response => {
                return response;
            }, (err: any) => {
                return err;
            })
            );
    }

    downloadFileContent(bodyData: any) {
        return this.baseService.getDownloadFileContent(`note/download?id=${bodyData.id}`);
    }
}
