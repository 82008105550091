import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";

import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {of} from 'rxjs';

import {Constants} from "../constants";
import {AuthService, SubscribersService} from "../services";
import {AlertService} from "@coreservices";

// Error Interceptor
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  //#region Constructor
  constructor(
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private subscribersService: SubscribersService
  ) {
  }

  //#region functions
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let pathArray = location.pathname.split('/');
      if (pathArray.length >= 2 && pathArray[1] != 'admin') {
        let error = '';
        if (err.error.data !== undefined) {
          error = err.error.data.message ? err.error.data.message : err.statusText;
        } else {
          error = err.statusText;
        }
        if ([401, 403, 500, 400, 404, 405, 406, 413, 415, 408, 501, 502, 422, 200].indexOf(err.status) !== -1) {

          // if 401 Unauthorized or 403 Forbidden response returned from api
          if (err.status == 403) {
            this.alertService.error(Constants.FORBIDDEN);
            this.router.navigate(['403']);
          } else if (err.status == 401) {
            // if (err.error.message !== undefined) {
            //     this.alertService.error(err.error.message);
            // } else if (err.error.data !== undefined) {
            //     this.alertService.error(err.error.data.message);
            // } else {
            //     error = err.statusText;
            //     this.alertService.error(error);
            // }
            // this.router.navigate(['/login']);
            // this.authService.clearToken();
            this.authService.clearToken();
            if (err.error.message !== undefined) {
              this.alertService.error(err.error.message);
            } else if (err.error.data !== undefined) {
              this.alertService.error(err.error.data.message);
            } else {
              error = err.statusText;
              this.alertService.error(error);
            }
            localStorage.removeItem('sampleOrder')
            localStorage.removeItem('token');
            localStorage.removeItem('loader');
            localStorage.removeItem('admin_user');
            this.subscribersService.changed_username.next("");
            this.subscribersService.userLoggedOut(true);
            this.router.navigate(['/login']);
          } else if (err.status == 200) {
            this.alertService.success(error);
            this.router.navigate(["/login"]);
          } else if (err.status == 500) {
            if (pathArray[1] == 'admin' && pathArray[2] == 'quote-orders' && pathArray[3] == 'view') {
              return throwError(err);
            } else {
              this.alertService.error(Constants.INTERNAL_SERVER_ERROR);
            }

            // this.router.navigate(['/admin']);
          } else if (err.status == 400) {
            if (error == undefined) {
              this.alertService.error(Constants.BED_REQUEST);
            } else {
              this.alertService.error(error);
            }
          } else if (err.status == 404) {
            if (location.pathname.indexOf('/reset-password') !== -1) {
              this.router.navigate(['/login']);
              this.alertService.error(err.error.data.message);
            } else {
              if (error == undefined || error == 'Object not found: 500') {
                this.alertService.error(Constants.REQUEST_NOT_FOUND);
              } else {
                this.alertService.error(error);
              }
            }
            //return of(err);
          } else if (err.status === 405) {
            this.alertService.error(Constants.METHOD_NOT_ALLOW);
            return of(err);
          } else if (err.status === 406) {
            this.alertService.error(Constants.NOT_ACCEPTABLE);
            return of(err);
          } else if (err.status === 413) {
            this.alertService.error(Constants.REQUEST_ENTITY_TOO_LARGE);
            return of(err);
          } else if (err.status === 415) {
            this.alertService.error(Constants.UN_SUPPORT_MEDIA);
            return of(err);
          } else if (err.status === 408) {
            this.alertService.error(Constants.REQUEST_TIME_OUT);
            return of(err);
          } else if (err.status === 500) {
            this.alertService.error(Constants.INTERNAL_SERVER_ERROR);
            return of(err);
          } else if (err.status === 501) {
            this.alertService.error(Constants.NOT_IMPLEMENTED);
            return of(err);
          } else if (err.status === 502) {
            this.alertService.error(Constants.BED_GATEWAY);
            return of(err);
          } else if (err.status === 503) {
            this.alertService.error(Constants.SERVICE_UNAVAILABLE);
            return of(err);
          } else if (err.status === 504) {
            this.alertService.error(Constants.GATEWAY_TIME_OUT);
            return of(err);
          } else if (err.status == 422) {
            return throwError(err);
          }
        } else {
          this.alertService.error("danger", error);
          return throwError(err);
        }
        return throwError(error);
      } else {
        return throwError(err);
      }
    }));
  }

  //#endregion
}
