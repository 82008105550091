import {Injectable} from '@angular/core';
import {RequestParamModel} from '../models';
import {PaginationModel} from '../models';
import {BaseService} from '../services';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonSearchService {
  constructor(
    private baseService: BaseService
  ) {
  }

  // getSearchResult(requestParamModel: RequestParamModel): Observable<any> {
  //   return this.baseService.get('common-search', requestParamModel)
  //     .pipe(map<any, any>(response => {
  //       return response.body.data;
  //     }, (err: any) => {
  //       return err;
  //     }));
  // }

  getSearchResult(requestParamModel: RequestParamModel): Observable<any> {
    return this.baseService.get('common-search', requestParamModel)
      .pipe(map<any, any>(response => {
        const searchResults = new PaginationModel<any>();
        const headers = response.headers;
        searchResults.setHeaders(headers);
        searchResults.data = response.body.data as any;
        return searchResults;
      }, (err: any) => {
        return err;
      }));
  }
}
