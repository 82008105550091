<!-- Modal Header -->
<div class="modal-header">
  <h4 class="modal-title">Search</h4>
</div>
<!-- Modal body -->
<div class="modal-body">
  <div class="searchBox-main">
    <div class="searchBox-input">
      <div class="searchBox-input-inner">
        <i class="fa fa-search"></i>
        <ng-autocomplete [data]="searchResult" [searchKeyword]="keyword"
                         (selected)='selectEvent($event)' (inputChanged)='search($event)'
                         [itemTemplate]="itemTemplate" (inputCleared)="searchCleared()"
                         [notFoundTemplate]="notFoundTemplate" [debounceTime]="600"
                         [isLoading]="iskeywordSearchLoading"
                         (scrolledToEnd)="onScroll()"
                         placeholder="Search account, contact, sample request, quote, order">
        </ng-autocomplete>
        <i aria-label="Close" class="material-icons" (click)="close()">close</i>
      </div>
      <ng-template #itemTemplate let-item>
        <i class="fa fa-search"></i>
        <div class="search-result-inside">{{item.title}}
            <span *ngIf="item.sub_title">({{item.sub_title}})</span>/
          <span class="search-result-breadcrumb">{{item.module}}</span>
        </div>
      </ng-template>

      <ng-template #notFoundTemplate let-notFound>
        {{errorMsg}}
      </ng-template>
    </div>
  </div>
</div>
