import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendUserModel, RequestParamModel, ResponseModel, UserModel} from '../models';
import {PaginationModel} from '../models';
import {BaseService} from '../services';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SettingService {

    constructor(private baseService: BaseService) {
    }

    // get setting details
    getSettingDetail(requestParamModel: RequestParamModel): any {
        return this.baseService.get('setting', requestParamModel)
            .pipe(map<any, any>(response => {
                response.body.data;
            }, (err: any) => {
                return err;
            }));
    }

    // get all user list
    getSettingDetailView(requestParamModel: RequestParamModel): any {
        return this.baseService.get('setting/view', requestParamModel)
            .pipe(map<any, any>(response => {
                return response.body.data;
            }, (err: any) => {
                return err;
            }));
    }

    save(requestParamModel: any): any {
        return this.baseService.post('setting/update', requestParamModel)
            .pipe(map<any, any>(response => {
                // return response.body;
                return response.data as any;
            }, (err: any) => {
                return err;
            }));
    }

    getDownloadEligibilityCriteria(): any {
        return this.baseService.getWithoutToken('setting/download-eligibility-criteria')
            .pipe(map<any, any>(response => {
                return response.body.data;
            }, (err: any) => {
                return err;
            }));
    }
}
